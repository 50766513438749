/**
 * Type: ページ
 * What: MLB café FUKUOKAページ
 */
import React, { useEffect } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
// import { scroller } from 'react-scroll';
// import Scrollbar from 'smooth-scrollbar';
import Parallax from 'react-rellax';
import { Btn, Title, Text, Button } from '../../components/Btn';
import Layout from '../../components/Layout';
import SEO from '../../components/Seo';
import BreadcrumbComponent from '../../components/Breadcrumb';
import SimpleSwiper from '../../components/Slider.fade';
import Image from '../../util/Image';
import MLB_SUMMER from '../../config/Mlb';
import ContentsListMlb from '../../components/ContentsListMlb';

import PostListComponent from '../../components/PostList/PostList.mlbcafe-fukuoka';

import useMediaQuery from '../../util/useMediaQuery';

const mq = useMediaQuery;

const pageTitle = 'MLB café FUKUOKA';
const pageDescription =
  '九州初出店！革新的な「食」のエンタメを演出することができるカジュアルアメリカンダイニング＆スポーツバー。「MLB café」は、Major League Baseball（MLB）から公認ライセンスを許諾された世界初のレストラン。日本では東京・恵比寿店、東京ドーム店に続き3店舗目。本場アメリカのスタジアムメニューをイメージした福岡店限定メニューも販売！';
const pageSlug = 'mlbcafe';
const pageLogo = `logo-${pageSlug}.png`;
// const pagebtnLogo = `btn-${pageSlug}.png`;

const sliderDataobj = [
  {
    filename: 'main-mlbcafe01.jpg',
  },
  {
    filename: 'main-mlbcafe02.jpg',
  },
];

const Detail = {
  // name: page2Title, // 店名
  description: '', // 説明
  item1: '', // 代表メニュー1商品名
  image1: '', // 代表メニュー1画像
  item2: '', // 代表メニュー2商品名
  image2: '', // 代表メニュー2画像
  mainMenu: '', // 代表メニュー
  time: '', // 営業時間
  tel: '092-687-0428', // 電話
  reserve: '', // 予約
  seat: '', // 座席数
  pay: '', // 対応決済
  other: '', // その他
  url: 'https://fukuoka.mlbcafe.jp/', // URL
  sns: ['', '', ''], // SNS
  caution: '', // 注意事項
  holidayTitle: '定休日', // 定休日タイトル
  holidayText: 'なし', // 定休日に表示するテキスト
};

// Data Props Template
const Template = () => {
  const data = useStaticQuery(graphql`
    {
      allWordpressWpDeal(filter: { deal_category: { eq: 15 } }, limit: 20) {
        nodes {
          title
          excerpt
          wordpress_id
        }
      }
    }
  `);
  const deal = data.allWordpressWpDeal.nodes;
  // const scrollToTarget = (e, elem, offset) => {
  //   e.preventDefault();
  //   const $offset = offset || -110;
  //   scroller.scrollTo(elem, {
  //     duration: 1200,
  //     delay: 0,
  //     offset: $offset,
  //     smooth: 'easeOutQuint',
  //   });
  // };
  // 初回DOM
  useEffect(() => {
    // const scrollbarOptions = {
    //   damping: 0.125,
    //   renderByPixels: true,
    //   alwaysShowTracks: true,
    // };
    // deal.length && Scrollbar.init(document.querySelector('#js-scrollbar'), scrollbarOptions);
  }, [deal.length]);
  return (
    <Layout pageSlug={pageSlug} bg="bg-3">
      <SEO title={pageTitle} description={pageDescription} />
      <BreadcrumbComponent hierarchy={2} bread2title={pageTitle} />
      <section className="pageMain bs-3">
        <div className="kv-wrap bg-3">
          <div className="kv-inner">
            <SimpleSwiper data={sliderDataobj} />
          </div>
          <div className="container">
            <div className="inner">
              <div className="pageMain-header">
                <div className="floorNo c-3">
                  <span>3</span>
                  <span>F</span>
                </div>
                <h2 className="ttl">
                  <Image src={pageLogo} alt={pageTitle} />
                </h2>
              </div>
              <div className="overview">
                {/* prettier-ignore */}
                <p className="text">
                  「MLB café」は、Major League Baseball（MLB）から公認ライセンスを許諾された世界初のカジュアルアメリカンダイニング＆スポーツバー。
                  <br />
                  BOSS E・ZO FUKUOKAにある福岡店が日本唯一のレストランスタイルで、アメリカの球場にいるような雰囲気の中で本場のグルメをお楽しみいただけます。
                </p>
              </div>
              <div className="youtube-wrap cn-hide">
                <iframe
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/O82ghMdLOck"
                  frameBorder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                />
              </div>
              {/*
              <div className="section-link">
                <ul className="bs-9">
                  <li>
                    <a
                      href="#menu"
                      onClick={(e) => scrollToTarget(e, 'menu')}
                    >
                      <span
                        className="arrow"
                      >
                        MENU
                      </span>
                    </a>
                  </li>
                  {deal.length ? (
                    <li>
                      <a
                        href="#otokuinfo"
                        onClick={(e) => scrollToTarget(e, 'otokuinfo')}
                      >
                        <span
                          className="arrow"
                        >
                          お得情報
                        </span>
                      </a>
                    </li>
                  ) : null}
                  <li>
                    <a
                      href="#specialexhibition"
                      onClick={(e) => scrollToTarget(e, 'specialexhibition')}
                    >
                      <span
                        className="arrow"
                      >
                        店舗情報
                      </span>
                    </a>
                  </li>
                </ul>
              </div>
              */}
            </div>
          </div>
        </div>
      </section>
      <PostListComponent />
      {/* deal.length ? (
        <section className="section sc-otokuinfo bg-3" id="otokuinfo">
          <div className="container">
            <h2 className="title is-2 c-12">
              お得情報
            </h2>
            <div className="sc-otokuinfo-wrap scrollbar" id="js-scrollbar">
              {
                deal.map((item) => (
                  <dl key={item.title}>
                    <dt className="otoku">お得情報</dt>
                    <dd className="bg-3">
                      <Link to={`/deal/${item.wordpress_id}`}>
                        <div
                          dangerouslySetInnerHTML={{ __html: item.excerpt }}
                        />
                        <Image src="breadcrumb-arrow3.svg" />
                      </Link>
                    </dd>
                  </dl>
                ))
              }
            </div>
          </div>
        </section>
      ) : null */}
      <section className="parallax-img" id="">
        <Parallax speed={0.5} percentage={-0.5}>
          <Image src="main-mlbcafe03.jpg" />
        </Parallax>
      </section>
      <section className="section bg-13" id="menu">
        <div className="container">
          <div className="sc-columns mb60">
            <div
              className="col col-6 floorbc3 none"
              data-sal="slide-up"
              data-sal-easing="ease-out-expo"
            >
              <div className="col-inner">
                <div className="col-image">
                  <figure className="image">
                    <Image filename="mlb-drink01.jpg" />
                  </figure>
                </div>
                {/*
                <div className="col-content">
                  <h3 className="ttl fsz14">商品名商品名</h3>
                </div>
                */}
              </div>
            </div>
            <div
              className="col col-6 floorbc3 none"
              data-sal="slide-up"
              data-sal-easing="ease-out-expo"
            >
              <div className="col-inner">
                <div className="col-image">
                  <figure className="image">
                    <Image filename="mlb-food01.jpg" />
                  </figure>
                </div>
                {/*
                <div className="col-content">
                  <h3 className="ttl fsz14">商品名商品名</h3>
                </div>
                */}
              </div>
            </div>
          </div>
          <h3 className="headline">
            <span className="c-17">店舗情報</span>
          </h3>
          <div className="content-box bg-16 mb60">
            <table className="table is-narrow is-fullwidth mb40 sp-mb30">
              <tbody>
                {Detail.holidayText && (
                  <tr>
                    {Detail.holidayTitle && <th>{Detail.holidayTitle}</th>}
                    <td className="tal">{Detail.holidayText}</td>
                  </tr>
                )}
                {Detail.tel ? (
                  <tr>
                    <th>電話番号</th>
                    <td className="tal">{Detail.tel}</td>
                  </tr>
                ) : null}
                <tr>
                  <th>店舗公式サイト</th>
                  <td className="tal">
                    {Detail.url ? (
                      <p className="underline-link">
                        <a
                          href={Detail.url}
                          className=""
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {Detail.url}
                        </a>
                      </p>
                    ) : (
                      'なし'
                    )}
                    <ul className="sns">
                      {Detail.sns[0] && (
                        <li>
                          <a
                            href={Detail.sns[0]}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="fb"
                          >
                            <Image
                              src="icon-fb.png"
                              alt="facebook"
                              width="22px"
                              height="22px"
                            />
                          </a>
                        </li>
                      )}
                      {Detail.sns[1] && (
                        <li>
                          <a
                            href={Detail.sns[1]}
                            className="tw"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <Image
                              src="icon-tw.png"
                              alt="twitter"
                              target="_blank"
                              width="22px"
                              height="22px"
                            />
                          </a>
                        </li>
                      )}
                      {Detail.sns[2] && (
                        <li>
                          <a
                            href={Detail.sns[2]}
                            className="ig"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <Image
                              src="ig.svg"
                              alt="instagram"
                              target="_blank"
                              width="22px"
                              height="22px"
                            />
                          </a>
                        </li>
                      )}
                    </ul>
                  </td>
                </tr>
              </tbody>
            </table>
            <p className="sp-mb20 tac">
              グッズ販売は免税手続きの対象です。詳細は
              <a href="/tax-free/" className="tx-link">
                こちら
              </a>
            </p>
          </div>
          {/* <h2 className="title is-2 en2 shadow-3"><Title>Menu</Title></h2> */}
          <div className="inner mb-6">
            <div className="btn-wrap mb60">
              <Button href="/the-foodhall/">店舗一覧へ戻る</Button>
            </div>
          </div>
          {/*
          <h4 className="title is-4 c-3 has-text-align-center">
            主なメニュー
          </h4>
          <p className="tac mb60">軽食、ランチ、ディナー、カフェ、ドリンク、アルコールなど。キッズメニュー有り。テイクアウト可。</p>
          <div
            className="btn-wrap mb60"
            data-sal="slide-up"
            data-sal-easing="ease-out-expo"
          >
            <Btn href="/menu?/" className="btn-l btn-pdf bg-3" target="_blank">
              <Text>
                メニューを見る
              </Text>
            </Btn>
          </div>

          <div className="menu box bs-3" id="specialexhibition">
            <h2 className="title is-2 en2 shadow-3">
              { mq('pc') ? (
                <Title width="600">Special party plan</Title>
              ) : (
                <>
                  <Title width="">Special</Title>
                  <Title width="">party plan</Title>
                </>
              )}
            </h2>

            <h3 className="title is-4 c-3">
              MLB café Girls コース
            </h3>

            <div className="plan is-flex">
              <div className="img">
                <Image filename="default.png" />
              </div>
              <div className="plan-detail">
                <h4 className="title is-6">
                  FOOD【全7品】
                </h4>
                <ul className="plan-list">
                  <li>シーザーサラダ</li>
                  <li>ポークパストラミサンド</li>
                  <li>おまかせピザ</li>
                  <li>ポップコーンシュリンプ</li>
                  <li>オニオンリング</li>
                  <li>ローストビーフ</li>
                  <li>アップルパイ</li>
                </ul>

                <h4 className="title is-6">
                  DRINK【120分飲み放題付き】
                </h4>
                <ul className="plan-list">
                  <li>ビール、ワイン、カクテル各種、ソフトドリンクなど</li>
                </ul>
              </div>
            </div>

            <h3 className="title is-4 c-3">
              MLB café Special コース
            </h3>

            <div className="plan is-flex">
              <div className="img">
                <Image filename="default.png" />
              </div>
              <div className="plan-detail">
                <h4 className="title is-6">
                  FOOD【全7品】
                </h4>
                <ul className="plan-list">
                  <li>シーザーサラダ</li>
                  <li>ポークパストラミサンド</li>
                  <li>おまかせピザ</li>
                  <li>ポップコーンシュリンプ</li>
                  <li>オニオンリング</li>
                  <li>ローストビーフ</li>
                  <li>アップルパイ</li>
                </ul>

                <h4 className="title is-6">
                  DRINK【120分飲み放題付き】
                </h4>
                <ul className="plan-list">
                  <li>ビール、ワイン、カクテル各種、ソフトドリンクなど</li>
                </ul>
              </div>
            </div>

            <div
              className="btn-wrap"
              data-sal="slide-up"
              data-sal-easing="ease-out-expo"
            >
              <Btn href="/menu?/" className="btn-l btn-pdf bg-3" target="_blank">
                <Text>
                  メニューを見る
                </Text>
              </Btn>
            </div>

          </div>
          */}
        </div>
      </section>

      {/*
      <section className="bg-12" id="">
        <Link to="/" className="caver-link">
          <Parallax speed={0.5} percentage={-0.5}>
            <Image src="caver-link-mlbcafe" />
          </Parallax>

          <div className="caver-link-text">
            <span className="en2">
              Reservation
            </span>
            <b>ご予約はこちらから</b>
          </div>

        </Link>
      </section>
      */}

      {/*
      <section className="section sc-paypay bg-13" id="">
        <div className="container">
          <div className="inner">
            <div className="content-box bg-16">
              <h2 className="title is-3 en2 c-17 has-text-align-center">Information</h2>
              <table className="table is-narrow is-fullwidth">
                <tbody>
                  <tr>
                    <th>予約</th>
                    <td className="tal">
                      可
                    </td>
                  </tr>
                  <tr>
                    <th>座席数</th>
                    <td className="tal">
                      ダミーテキストダミーテキストダミーテキストダミーテキスト
                    </td>
                  </tr>
                  <tr>
                    <th>決済情報</th>
                    <td className="tal">
                      現金、各種クレジットカード、PayPay、ほか
                    </td>
                  </tr>
                  <tr>
                    <th>その他</th>
                    <td className="tal">
                      ダミーテキストダミーテキストダミーテキストダミーテキスト
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </section>
      */}
    </Layout>
  );
};

export default Template;
